@import "../../App.css";
@import "../../styles/container.css";
@import "../../styles/backgrounds.css";

.event-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.event-row {
  display: flex;
  flex-direction: row;
}

.event-row-reverse .event-intro {
  margin-left: auto;
}

.event-row .event-intro {
  margin-right: auto;
}

.event-intro {
  max-width: 480px;
}

.event-intro .title {
  margin-bottom: 24px;
}

.event-intro .paragraph {
  line-height: 26px;
}

@media only screen and (max-width: 920px) {
  .event-row,
  .event-row-reverse {
    flex-direction: column;
    align-items: center;
  }

  .event-row .event-intro,
  .event-row-reverse .event-intro {
    margin: auto;
  }

  .event-intro {
    text-align: center;
  }

  .event-intro-container-1 .intro-image,
  .event-intro-container-3 .intro-image,
  .event-intro-container-4 .intro-image {
    margin-bottom: calc(15vw);
  }

  .event-intro-container-5 .intro-image {
    margin-top: calc(5vw);
    margin-bottom: calc(5vw);
  }

  .event-intro-container-1 .intro-image > img,
  .event-intro-container-3 .intro-image > img,
  .event-intro-container-4 .intro-image > img,
  .event-intro-container-5 .intro-image > img {
    width: 100%;
  }
}