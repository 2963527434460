@import "../index.css";

.partners {
  /* padding: 3.75rem 0; */
  padding-bottom: 9rem;
}

.partners h1 {
  text-align: center;
  margin-bottom: 46px;
}

.partner-logos {
  max-width: 832px;
  flex-wrap: wrap;
}

.partner-logo {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  width: 208px;
  /* flex-direction: column; */
}

@media only screen and (max-width: 1440px) {
  
}
@media only screen and (max-width: 1280px) {

}
@media only screen and (max-width: 1024px) {
  
}
@media only screen and (max-width: 912px) {
  .partner-logos {
    max-width: 624px;
  }
}
@media only screen and (max-width: 664px) {
  .partner-logos {
    max-width: 416px;
  }
}

@media only screen and (max-width: 456px) {
  .partner-logos {
    flex-direction: column;
  }
}
