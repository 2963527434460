@import "../index.css";

.event-offers-section {
  color: #ffffff;
  padding-top: 113px;
  padding-bottom: 124px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-offers-section .title {
  margin-bottom: 80px;
  text-align: center;
}

.event-offer-items {
  max-width: 940px;
  /* column-gap: 146px; */
  column-gap: 35px;
  row-gap: 33px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.event-offer-items .event-offer-item {
  width: 216px;
  height: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-offer-items .event-offer-item .item-icon {
  height: 164px;
  width: 164px;
}
.event-offer-items .event-offer-item .sub-title {
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
}

@media only screen and (max-width: 920px) {
  .event-offers-section {
    padding-top: calc(20px + 10vw);
    padding-bottom: calc(95px + 7.5vw);
  }
  .event-offers-section .title {
    margin-bottom: calc(20px + 10vw);
  }
  .event-offer-items {
    max-width: 630px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 600px) {
  .event-offer-items {
    max-width: 220px;
  }
}