@import "../index.css";

.introduction2 {
  padding-top: 89px;
  padding-bottom: 112px;

  color: #FFFFFF;
  text-align: center;
}

.introduction2 .title {
  margin: auto;
  max-width: 700px;
  margin-bottom: 37px;

  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  text-align: center;
}
.introduction2 .paragraph {
  margin: auto;
  max-width: 858px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  color: #FFFFFF;
}