@import "../../styles/backgrounds.css";
@import "../../styles/fonts.css";
@import "../../styles/container.css";

.demo-upper-top {
  color: white;
  padding-top: 142px;
  padding-bottom: 112px;
}

.demo-upper-bot {
  padding-top: 64px;
  /* padding-bottom: 64px; */
  min-height: 200px;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* align-items: center; */
}

.demo-upper-bot .paragraph {
  margin-bottom: 42px;
}

.demo-upper-top .left {
  max-width: 50%;
  width: calc(100vw - 470px - 80px);
}
.demo-upper-bot .left {
  max-width: 49%;
  width: calc(100vw - 470px - 80px);
}

.demo-form-container {
  width: 470px;
  position: relative;
}

.demo-form-container .demo-form {
  position: absolute;
  top: -449px;
}

.demo-title {
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 1em;
}

.demo-subtitle-row {
  height: 77px;

  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: center;
}

.demo-subtitle {
  width: 338px;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.demo-paragraph {
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
}

.demo-icon {
  height: 0px;
  /* width: 0px; */
  width: 0px;
}

.demo-icon > img {
  position: relative;
  top: -100px;
}

@media only screen and (max-width: 1130px) {
  .demo-subtitle-row {
    height: fit-content;
    display: block;
  }

  .demo-icon {
    height: 55px;
  }

  .demo-icon > img {
    top: 0;
  }

  .demo-upper-bot {
    min-height: 166px;
  }

  .demo-form-container .demo-form {
    top: -483px;
  }
}

@media only screen and (max-width: 930px) {

  .demo-upper-top {
    padding-bottom: calc(2.75vw);
  }

  .demo-upper-top,
  .demo-upper-bot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .demo-upper-top .left,
  .demo-upper-bot .left {
    max-width: 473px;
    width: auto;
  }

  .left .demo-title,
  .left .demo-subtitle,
  .left .demo-paragraph {
    text-align: center;
  }

  .demo-subtitle-row {
    display: flex;
    flex-direction: column;
  }

  .demo-subtitle-row .demo-icon {
    width: auto;
    height: auto;
  }

  .demo-form-container {
    /* height: 640px; */
    margin-bottom: calc(86px + 2.5vw);
  }

  .demo-form-container .demo-form {
    position: relative;
    /* display: none; */
    top: 0;
    /* right: 0; */
    left: 0;
    height: fit-content;
    width: fit-content;
  }
}
