@import "../index.css";

.event-intro-container-1 {
  padding-top: 86px;
}

.event-intro-container-1 .intro-image {
  position: relative;
  left: -50px;
  /* max-height: 454px; */
  max-width: 466px;
  min-width: 430px;
  /* width: 466px; */
  /* height: 454px; */
}

.event-intro-container-1 .intro-image img {
  /* position: absolute; */
  width: 100%;
  /* height: 100%; */
  /* left: -50px; */
  /* width: 466px; */
}

.event-intro-container-1 .event-intro {
  max-width: 540px;
  padding-top: 1rem;
}

.event-intro-container-1 .event-intro .title {
  margin-bottom: 13px;

  line-height: 44px;
  letter-spacing: 0.03em;
}

.event-intro-container-1 .event-intro .sub-title {
  margin-bottom: 27px;
  line-height: 22px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  background-image: linear-gradient(90.31deg, #48B6CA 29.98%, #0094FB 91.64%);

  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.event-intro-container-1 .event-intro .paragraph {
  margin-bottom: 38px;
  line-height: 26px;
}

.event-intro-container-1 .event-intro .btn {
  margin-bottom: 6rem;
}

@media only screen and (max-width: 920px) {
  .event-intro-container-1 .event-intro {
    max-width: 500px;
  }
  .event-intro-container-1 .event-intro .btn {
    margin-bottom: 60px;
  }
  .event-intro-container-1 .intro-image {
    left: 0;
    margin-bottom: 50px;
    width: auto;
    height: auto;
  }
}