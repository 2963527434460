@import "../index.css";

.event-feature-section {
  padding-top: 65px;
  padding-bottom: 110px;
}

.event-intro-container-3,
.event-intro-container-4,
.event-intro-container-5 {
  align-items: center;
}

.event-intro-container-3 .intro-image,
.event-intro-container-4 .intro-image,
.event-intro-container-5 .intro-image {
  height: 340px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.event-intro-container-3 .intro-image > img {
  max-width: 465px;
  max-height: 330px;
}

.event-intro-container-5 {
  margin-bottom: 63px;
}

@media only screen and (max-width: 920px) {
  .event-feature-section {
    padding-top: calc(12.5vw);
    padding-bottom: calc(15vw);
  }
}