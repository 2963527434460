
.feature-background {
  background-color: #1a212e;
  background-image: url("../img/feature_background.png");
  /* background-repeat: no-repeat; */
  /* background-position: top; */
  background-position: center;
  background-size: cover;
}
.mask-group-1-background {
  background-image: url("../img/mask_group_1_background.png");
  /* background-repeat: no-repeat; */
  /* background-position: top; */
  background-position: center;
  background-size: cover;
}
.feature-background-2 {
  background-image: url("../img/feature_background2.png");
  background-position: center;
  background-size: cover;
}
.feature-background-3 {
  background-image: url("../img/feature_background3.png");
  background-position: center;
  background-size: cover;
}
.feature-background-4 {
  background-image: url("../img/feature_background4.png");
  background-position: center;
  background-size: cover;
}
.mask-background-2 {
  background-image: url("../img/mask_background_2.png");
  background-position: center;
  background-size: cover;
}

.light-background {
  background-color: #eaf0fc;
}

.dark-background {
  background: rgba(26, 33, 46, 1);
}

.dark-background-2 {
  background: #1A212E;
}