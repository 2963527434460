@import "../index.css";

.event-pricing {
  padding-top: 136px;
  padding-bottom: 171px;
}

.event-pricing .title {
  text-align: center;
  margin-bottom: 2em;
}

.event-pricing-items-row {
  margin-left: auto;
  margin-right: auto;
  width: 1028px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 44px;
}

.event-pricing-item {
  height: 395px;
  width: 316px;

  height: 365px;
  width: 306px;

  padding: 32px 0 0;

  border: 1px solid #0094fb;
  box-sizing: border-box;
  border-radius: 20px;

  transition: 0.15s;
}

.event-pricing-item:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.event-pricing-item .title {
  font-weight: 600;
  font-size: 18px;
  /* line-height: 22px; */
  margin-bottom: 23px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  background-color: #676767;

  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.event-pricing-item .title.featured {
  background-image: linear-gradient(95.97deg, #0094fb -6.89%, #49baca 99.13%);
}

.event-pricing-item .pricing {
  display: flex;
  align-items: baseline;
  justify-content: center;
  column-gap: 8px;

  margin-bottom: 27px;
}

.event-pricing-item .pricing .text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.event-pricing-item .pricing .number {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  color: #0094fb;

  min-height: 44px;
}

.event-pricing-item .services {
  padding-left: 40px;
  padding-right: 10px;
}

.event-pricing-item .services .sub-title {
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  
  color: #000000;
  
  margin-bottom: 16px;
}

.event-pricing-item .services .service-item {
  display: flex;
  align-items: flex-start;
}

.event-pricing-item .services .service-item .vector {
  width: 11px;
  /* height: 8px; */
  height: 24px;
  margin-right: 11.5px;

  display: flex;
  align-items: center;
}

.event-pricing-item .services .service-item .vector > img {
  display: inline;
}

.event-pricing-item .services .service-item .paragraph {
  font-size: 14px;
  line-height: 24px;
}

.event-pricing-item .price-item-btn {
  margin-top: 31px;
  text-align: center;
}

@media only screen and (max-width: 1108px) {
  .event-pricing {
    padding-bottom: 150px;
  }

  .event-pricing-items-row {
    width: 672px;
  }
}

@media only screen and (max-width: 930px) {
  .event-pricing {
    padding-top: calc(45px + 12vw);
  }
  /* .event-pricing .title {
    display: none;
  } */
}

@media only screen and (max-width: 752px) {
  .event-pricing {
    padding-bottom: 119px;
  }

  .event-pricing-items-row {
    width: 316px;
  }

}