@import "../index.css";

.form-block {
  padding-top: 133px;
  padding-bottom: 137px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.form-block .form-block-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 58px;
}

.impact-item-2 {
  width: 362px;
  height: 177px;
  padding: 0px 27px;

  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 148, 251, 1);
  box-sizing: border-box;
  border-radius: 20px;

  display: flex;
  align-items: center;
  gap: 21px;
}

.impact-item-2 .impact-icon {
  /* height: 112px; */
  width: 112px;

  text-align: center;
}

.impact-item-2 .impact-content .impact-number {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 43px;

  color: #0094fb;
}

.impact-item-2 .impact-content .impact-title {
  width: 130px;
  
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #666666;

  margin-top: 17px;
}

.form-block-right .demo-form form {
  padding: 49px 100px 53px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

@media only screen and (max-width: 1024px) {
  .form-block {
    flex-direction: column;
  }
}