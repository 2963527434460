@import "../index.css";
.indicator-dot-row {
  display: flex;
  column-gap: 13px;
}

.indicator-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #c4c4c4;
}

.indicator-dot.active {
  background-color: #0094fb;
}

.event-success-stories {
  padding-top: 100px;
  padding-bottom: 120px;

  text-align: center;
}

.event-success-stories .title {
  text-align: center;
  margin-bottom: 96px;
}

.event-success-stories-slides {
  margin-bottom: 150px;
}

.event-success-stories-slides .slide-item {
  margin-left: auto;
  margin-right: auto;
  width: 1028px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-success-stories-slides .slide-item .slide-item-content {
  position: relative;
  max-width: 508px;
  text-align: left;
  padding-bottom: 11px;
}
.event-success-stories-slides
  .slide-item
  .slide-item-content
  .slide-item-content-indicators {
  margin-bottom: 38px;
}

.event-success-stories-slides
  .slide-item
  .slide-item-content
  .slide-item-content-message {
  font-weight: 600;

  margin-bottom: 64px;
}

.event-success-stories-slides
  .slide-item
  .slide-item-content
  .slide-item-content-footer {
  margin-bottom: 20px;
}

.event-success-stories-slides .slide-item .slide-item-content .slide-item-logo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.event-success-stories-slides .slide-item .slide-item-image > img {
  width: 477px;
  height: 319px;
}

@media only screen and (max-width: 1108px) {
  .event-success-stories {
    padding-bottom: 100px;
  }
  .event-success-stories-slides {
    margin-bottom: 100px;
  }
  .event-success-stories-slides .react-slideshow-wrapper .images-wrap > div {
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  .event-success-stories-slides .slide-item {
    width: 100%;
    flex-direction: column;
    row-gap: 75px;
  }
}

@media only screen and (max-width: 556px) {
  .event-success-stories .title {
    text-align: left;
  }
}

@media only screen and (max-width: 517px) {
  .event-success-stories-slides {
    margin-bottom: calc(18vw - 3.6px);
  }
  .event-success-stories-slides .slide-item {
    row-gap: 38px;
  }
  .event-success-stories-slides
    .slide-item
    .slide-item-content
    .slide-item-content-footer {
    margin-bottom: 34px;
  }
  .event-success-stories-slides
    .slide-item
    .slide-item-content
    .slide-item-logo {
    position: relative;
  }
  .event-success-stories-slides .slide-item .slide-item-image > img {
    width: 100%;
    height: calc((100vw - 40px) * 0.6688);
  }
}
