/* @import "../../App.css"; */
@import "../../styles/fonts.css";

.demo-form {
  position: relative;
}

.demo-form .demo-form-scroll-ref {
  height: 0px;
  position: absolute;
  top: -90px;
}

.demo-form .background-logo {
  position: absolute;
  right: 25px;
  bottom: 23px;
}

.demo-form .row {
  display: flex;
  flex-wrap: wrap;
}

.demo-form .form-product-options-row {
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  display: flex;

  column-gap: 1rem;
}

.product-option {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  width: 148px;
  height: 42px;
  padding: 12px 11px;

  border-radius: 6px;

  text-align: center;

  background-color: #ededed;

  transition: 0.2s ease;
}

.product-option.selected {
  border: 1px solid #0094fb;
  background-color: transparent;
  color: #0094fb;
}

.demo-form form {
  /* position: absolute; */
  /* width: 459px;
  height: 914px; */
  /* left: calc(50vw + 99px - 20px); */
  /* right: calc(25vw - 170px);
  top: 107px; */

  /* margin-bottom: 87px; */

  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  border-radius: 18px;

  padding: 56px 79px 89px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */

  color: #676767;

  width: 312px;
  box-sizing: content-box;
}

.demo-form .form-title {
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 28px;
}

.demo-form .term-policy {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;

  margin-bottom: 41px;
}

.demo-form .term-policy a {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;

  color: #0094fb;
}

.demo-form .form-fields {
  margin-bottom: 0.5rem;
}

.demo-form form label {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
}

.demo-form form input {
  font-family: Quicksand;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  box-sizing: border-box;
}

.demo-form form label.checkbox {
  display: flex;
}

.demo-form form .subscribe-checkbox {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.demo-form form input[type="checkbox"] {
  height: 15px;
  width: 15px;
  margin: auto 0.5rem auto 0;

  border: 1.2px solid #676767;
  box-sizing: border-box;
  border-radius: 2px;

  visibility: hidden;
  height: 0;
  width: 0;
  margin: 0;
}
.checkbox {
  display: block;
  position: relative;
  /* padding-left: 45px; */
  /* margin-bottom: 15px; */
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: 20px;
}
/* Creating a custom checkbox
        based on demand */
.checkmark {
  position: absolute;
  top: 1.5px;
  left: 0;
  height: 15px;
  width: 15px;

  border: 1.2px solid #676767;
  box-sizing: border-box;
  border-radius: 2px;
}

/* Specify the background color to be
      shown when hovering over checkbox */
/* .checkbox:hover input ~ .checkmark {
  background-color: yellow;
} */

/* Specify the background color to be
      shown when checkbox is active */
/* .checkbox input:active ~ .checkmark {
  background-color: red;
} */

/* Specify the background color to be
      shown when checkbox is checked */
.checkbox input:checked ~ .checkmark {
  background-color: #0094FB;
  border-color: #0094FB;
}
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
      showing only two border to make it look
      like a tickmark */
.checkbox .checkmark:after {
  line-height: normal;
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  top: 2px;
  left: 1.5px;
  --c:white; /* Color */
  --t:2.5px;  /* thickness */
  --r:2px;  /* Radius */
  --w:10.5px; /* width */
  --h:7px;   /* height */
  display: inline-block;
  height: var(--h);
  width: var(--w);
  border-bottom-left-radius: var(--r);
  background:
    radial-gradient(farthest-side at top right,transparent 97%,var(--c) 100%) 
    bottom calc(var(--t) - 0.5px) 
    left calc(var(--t) - 0.5px)/calc(var(--t) * 0.8) calc(var(--t) * 0.8),  
    radial-gradient(farthest-side,var(--c) 99%,transparent 100%) top left/var(--t) var(--t),
    radial-gradient(farthest-side,var(--c) 99%,transparent 100%) bottom right/var(--t) var(--t),
    linear-gradient(var(--c),var(--c)) left bottom/var(--t) calc(100% - var(--t)/2),
    linear-gradient(var(--c),var(--c)) left bottom/calc(100% - var(--t)/2) var(--t);
  background-repeat:no-repeat;
  transform:rotate(-45deg);
}

.demo-form form input[type="text"],
.demo-form form input[type="email"],
.demo-form form input[type="number"],
.demo-form form select {
  text-indent: 12px;

  display: block;
  width: 100%;
  height: 34px;
  border-radius: 3px;
  border: 1px solid #ededed;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);

  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.demo-form form input[type="text"]:focus-visible,
.demo-form form input[type="email"]:focus-visible,
.demo-form form input[type="number"]:focus-visible,
.demo-form form select:focus-visible {
  outline: none;
}

.demo-form form input::placeholder {
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  color: #c4c4c4;
  opacity: 1; /* Firefox */
}
.demo-form form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  color: #c4c4c4;
}
.demo-form form input::-webkit-input-placeholder {
  /* Microsoft Edge */
  font-family: Quicksand;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  color: #c4c4c4;
}

.demo-form form .input-row-left > label {
  width: 152.13px;
}

.demo-form form .input-row-right {
  display: flex;
  flex-direction: row-reverse;
}

.demo-form form .input-row-right > label {
  width: 152.13px;
}

.demo-form .input-width-6 {
  width: 50%;
}

.demo-form .input-width-6 > input {
  width: 100%;
}

.demo-form .input-width-11 {
  width: 94.67%;
}

.demo-form .input-width-12 {
  width: 100%;
}

.demo-form .center-btn {
  width: 100%;
  text-align: center;
}
.demo-form .center-btn > button {
  margin-left: auto;
  margin-right: auto;
}

.demo-form form .btn {
  width: 220px;
  height: 47px;
  color: white;
  background: #0094fb;
  border-radius: 6px;

  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.demo-form form .btn:hover {
  background: #4eb0f4;
}

.demo-form form .loading-btn {
  background-color: #4eb0f4;
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) {
  .intro-paragraph {
    width: 40%;
  }
}

@media only screen and (max-width: 1200px) {
  /* .demo-form form {
    right: calc(15vw - 99px);
  } */
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 885px) {
  .t2div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .t2div h1,
  .t2div h3,
  .t2div p {
    text-align: center;
  }
  /* .demo-form form {
    position: inherit;
    margin-bottom: calc((100vw - 459px) * 87 / 426);
  } */
  .intro-paragraph {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 459px) {
  #root {
    min-width: 329px;
    overflow: hidden;
  }
  /* .demo-form form {
    margin-bottom: 0px;
  } */
}
@media only screen and (max-width: 375px) {
}
