@import "../index.css";

.event-partners {
  padding-top: 1rem;
}

.event-partners .paragraph {
  color: rgba(0, 0, 0, 1);
  text-align: center;
}

.event-partner-logos {
  margin-top: 3rem;
  margin-bottom: 5rem;
}

.event-partner-logo {
  display: flex;
  justify-content: center;
  width: 208px;
}

@media only screen and (max-width: 920px) {
  .event-partners .paragraph {
    max-width: 500px;
  }
  .event-partner-logos {
    margin-top: 79px;
    margin-bottom: 112px;
    max-width: 500px;
    flex-wrap: wrap;
    row-gap: 69px;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 460px) {
  .event-partner-logos {
    width: 416px;
  }
}