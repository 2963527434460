@import "../index.css";

.event-intro-container-2 {
  color: #FFFFFF;
  padding-top: 141px;
  padding-bottom: 2.15rem;
}

.event-intro-container-2 .event-intro {
  max-width: 460px;
}

.event-intro-container-2 .event-intro .title {
  margin-bottom: 2.5rem;
}

.event-intro-container-2 .intro-image {
  padding-top: .5rem;
  width: 420px;
  height: 370px;
  position: relative;
}

.event-intro-container-2 .intro-image > img {
  position: absolute;
  left: -158px;
  bottom: -50px;
  width: 716px;
  
  /* height: 526px; */
}

@media only screen and (max-width: 920px) {
  .event-intro-container-2 {
    padding-top: 95px;
    padding-bottom: 4rem;
  }

  .event-intro-container-2 .event-intro .title {
    margin-bottom: 58px;
  }

  .event-intro-container-2 .event-intro .paragraph {
    margin-bottom: 2rem;
  }
  .event-intro-container-2 .intro-image > img {
    width: 170.4%;

    left: -35%;
    top: -40%;
  }
}

@media only screen and (max-width: 400px) {
  .event-intro-container-2 .intro-image {
    width: 100%;
    height: calc(92.5vw);
    position: relative;
    text-align: center;
  }
  .event-intro-container-2 .intro-image > img {
    top: -30%;
  }
}