@import "../../styles/container.css";
@import "../../App.css";

.talent-row {
  display: flex;
  flex-direction: row;
}

.talent-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.talent-row .talent-intro {
  margin-right: auto;
}

.talent-row-reverse .talent-intro {
  margin-left: auto;
}

@media only screen and (max-width: 920px) {
  .talent-row,
  .talent-row-reverse {
    flex-direction: column;
    align-items: center;
  }

  .talent-row .talent-intro,
  .talent-row-reverse .talent-intro {
    margin: auto;
  }

  .talent-intro {
    text-align: center;
  }

  .talent-intro-container-1 .intro-image > img,
  .talent-intro-container-2 .intro-image > img,
  .talent-intro-container-3 .intro-image > img,
  .talent-intro-container-4 .intro-image > img,
  .talent-intro-container-5 .intro-image > img {
    width: 100%;
  }
}