
.container-1 {
  max-width: 1030px;
  padding-left: 40px;
  padding-right: 40px;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .container-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}