@import "../../styles/container.css";

.navbar {
  /* overflow: hidden; */
  background-color: white;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  z-index: 10;

  box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
  /* opacity: 0.9; */
  /* padding: 1rem 0; */

  height: 83px;
}

.nav-logo {
  margin: 6px;
}

.nav-logo-div {
  background-image: url("/src/img/hackhub_logo.svg");
  height: 35px;
  width: 175px;
  background-repeat: no-repeat;
  margin-top: 26px;
  margin-bottom: 26px;
}

.navbar-left-width {
  width: 25%;
}

.navbar-right-width {
  width: 75%;
}

.navbar-options-icon {
  background-image: url("/src/img/Hamburger_1.svg");
  height: 25px;
  width: 25px;
}

.navbar-options {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  /* gap: 0.5rem; */
}

.navbar-option-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79px;
  width: 220px;
}

.navbar-product-option {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;

  color: #666666;

  box-sizing: border-box;

  background-color: white;

  width: 180px;

  transition: all 0.2s ease-out;
}

.navbar-product-option:hover {
  /* background: #dcf1ff; */
  color: #0094FB;
}

.mobile-dropdown ul {
  margin: 0;
  list-style: none;
  padding: 0;
  background-color: white;
}

.mobile-dropdown li {
  text-align: center;
}

.mobile-dropdown > ul {
  margin-top: 0.25rem;
}

.mobile-dropdown .dropdown-item,
.mobile-dropdown .navbar-option-item {
  width: 100%;
}

.arrow-down {
  margin-left: 8px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 8px solid;
}

.arrow-right {
  margin-left: 8px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;

  border-left: 8px solid;
}

@media only screen and (max-width: 860px) {
  .navbar-product-option {
    font-size: 18px;
  }
}
@media only screen and (max-width: 624px) {
  .nav-logo-div {
    background-image: url("/src/img/hackhub_logo_2.svg");
    height: 30px;
    width: 24px;
  }
}
