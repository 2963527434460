@import "../index.css";

.products {
  text-align: center;
  padding: 50px 0 112px;
}

.products-title {
  margin: 0.5rem 0;
  padding: 0 1rem;

  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}

.products-sub-title {
  margin: 0.5rem 0;
  padding: 0 2rem;

  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #0094fb;
}

.product-items {
  margin: 2.25rem 0;
}

.product-item {
  padding: 20px;
}

.product-card {
  /* width: 314px; */
  width: 300px;
  height: 430px;
  padding: 36px 0;
  text-align: left;

  display: block;

  background: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border-radius: 20px;

  transition: ease-in-out 0.3s;
}

.product-card-style1 {
  border: 1px solid rgba(72, 185, 202, 0.33);
  box-shadow: 0px 16px 30px 0px rgba(0, 148, 251, 0.1);
}

.product-card-style1 .product-card-body span {
  color: #49baca;
}

.product-card-style2 {
  border: 1px solid rgba(121, 148, 245, 0.33);
  box-shadow: 0px 16px 30px 0px rgba(110, 70, 198, 0.1);
}

.product-card-style2 .product-card-body span {
  color: #6473db;
}

.product-card-style3 {
  border: 1px solid rgba(219, 123, 255, 0.33);
  box-shadow: 0px 16px 30px rgba(252, 98, 180, 0.1);
}

.product-card-style3 .product-card-body span {
  color: #C400F5;
}

.product-card:hover {
  cursor: pointer;
  background: #1a212e;
}

.product-item-img {
  width: 152px;
  height: 172px;

  margin-left: 10px;
  margin-right: 10px;
}

/* .product-item-img img {
  height: 110%;
} */

.product-card .product-card-body {
  padding: 0 36px;
  text-align: left;
}

.product-card:hover .product-card-body h2,
.product-card:hover .product-card-body p {
  color: white;
}

.product-card .product-card-body h2,
.product-card .product-card-body p {
  margin: 14px 0;

  transition: ease-in-out 0.3s;
}

.product-card .product-card-body h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
}

.product-card .product-card-body span {
  font-family: Lato;
}

.product-card .product-card-body p {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;

  color: #000000;
}

@media only screen and (max-width: 1062px) {
  .product-items {
    /* width: px; */
    /* flex-wrap: wrap; */
    row-gap: 1rem;

    flex-direction: column;
  }
}

@media only screen and (max-width: 752px) {

  .products-title {
    max-width: 607px;
  }
}
