@import '../index.css';

.thank-you-body-container {
  display: flex;
  justify-content: center;
  gap: 54px;
  padding-top: 124px;
  padding-bottom: 266px;
}

.thank-you-body-container .thank-you-body {
  max-width: 554px;
  padding-top: 8px;
}

.thank-you-body .title {
  line-height: 48px;
  margin-bottom: 6px;
}

.thank-you-body .paragraph {
  margin-bottom: 29px;
}

.thank-you-body .btn {
  width: 240px;
}


@media only screen and (max-width: 668px) {
  .thank-you-body-container {
    flex-direction: column;
    align-items: center;
  }
  .thank-you-body-container .thank-you-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}