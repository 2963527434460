@import "../index.css";

.talent-features {
  padding: 96px 0px 181px;
  color: white;
}

.talent-features .title {
  margin-bottom: 120px;

  font-size: 36px;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
}

.feature-items-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 54px;
}

.feature-item {
  width: 400px;
}

.feature-item .item-icon {
  height: 160px;
  width: 160px;
}

.feature-item .title {
  margin-bottom: 27px;

  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.feature-item .paragraph {
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

@media only screen and (max-width: 920px) {

  .talent-features {
    padding: 96px 20px 160px;
  }

  .talent-features .title {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;

    max-width: 414px;
  }
  .feature-items-row {
    flex-direction: column;
    align-items: center;
  }
  .feature-item .item-icon {
    margin: auto;
  }

  .feature-item .title {
    text-align: center;
  }

  .feature-item .paragraph {
    text-align: center;
  }
}

@media only screen and (max-width: 480px) {

  .feature-item {
    width: 90%;
  }
}
