@import '../index.css';
.talent-foot-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  padding-top: 44px;
  padding-bottom: 58px;
}
.talent-foot-container .talent-foot-img {
  height: 348px;
  width: 348px;
  padding-bottom: 24px;
}
.talent-foot-container .talent-foot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 473px; */
}

.talent-foot-container .talent-foot .content {
  margin-bottom: 4rem;
  padding: 0 20px;
  max-width: 345px;

  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

@media only screen and (max-width: 715px) {
  .talent-foot-container {
    flex-direction: column;
  }
}