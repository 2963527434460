@import "../DemoForm/index.css";

.modal-form {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 11;
  opacity: 1;
}

.modal-form .modal-form-close-icon {
  position: absolute;
  height: 27px;
  width: 27px;

  z-index: 12;

  top: 38px;
  right: 64px;

  background-image: url("../../img/vector_cross.svg");
  background-repeat: no-repeat;

  transition: .2s ease;
}

.modal-form .modal-form-close-icon:hover {
  opacity: .8;
}


.modal-form .demo-form {
  margin: auto;
  margin-top: 46px;

  width: 100%;

  display: flex;
  justify-content: center;
}

.modal-form .demo-form form {
  padding-left: 145px;
  padding-right: 145px;
}

.modal-form .demo-form .title {
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.03em;

  color: #333333;
  margin-bottom: 28px;
}

@media only screen and (max-width: 660px) {
  .modal-form .modal-form-close-icon {
    top: 10px;
    right: 3%;
  }
}
@media only screen and (max-height: 778px) {
  .modal-form .demo-form {
    margin-top: 0;
  }
}
@media only screen and (max-height: 720px) {
  .modal-form .demo-form .term-policy {
    margin-bottom: 1rem;
  }
  .modal-form .demo-form form {
    padding-top: 27px;
  }
}

@media only screen and (max-width: 470px) and (max-height: 778px) {
  .modal-form .modal-form-close-icon {
    background-image: url("/src/img/vector_cross_dark.svg");
  }
}