@import "../index.css";

.home-row {
  padding: 34px 0 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-row .home-intro-img {
  /* width: 0px; */
  max-width: 600px;
}

.home-row .home-intro-img > img {
  /* width: 654px; */
  width: 110%;
}

.home-intro {
  margin-right: auto;
  /* padding-top: 4.8rem; */
  margin-right: 19px;
}

.home-intro .title {
  margin-bottom: 2rem;
  max-width: 380px;

  line-height: 48px;
  letter-spacing: 0.03em;
  text-align: left;
}

.home-intro .paragraph {
  margin-bottom: 4rem;
  max-width: 406px;

  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}


@media only screen and (max-width: 1080px) {
  .home-row {
    flex-direction: column;
    align-items: center;
  }
  .home-intro {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-intro .title {
    max-width: 467px;
    text-align: center;
  }

  .home-intro .paragraph {
    max-width: 434px;
    text-align: center;
  }

  .home-row img {
    width: 100%;
    /* max-width: 530px; */
  }

  .home-row .home-intro-img {
    width: auto;
  }
  .home-row .home-intro-img > img{
    width: 100%;
  }
}

@media only screen and (max-width: 585px) {
  .home-row .home-intro-img {
    width: 120%;
  }

}