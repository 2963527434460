@import "../../App.css";

.footer {
  background: linear-gradient(0deg, #0094fb, #0094fb);
  color: #ffffff;
  min-height: 488px;
  padding-top: 40px;
}

.footer .container div {
  margin: 1.25rem 0.5rem;
}

.footer h2 {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.12em;
  text-align: left;

  text-transform: uppercase;
}

.footer-main {
  display: flex;
  justify-content: space-between;

  min-height: 417px;
}

.footer .company-description {
  display: flex;
  flex-direction: column;
}

.footer .company-description .description-header-logo {
  height: 34px;
  width: 175px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.footer .company-description p {
  margin: 0.5rem 0;

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.footer p,
.footer a {
  margin: 0;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
}

.footer a {
  color: inherit;
  text-decoration: underline 2px rgba(255, 255, 255, 0);
  transition: text-decoration-color 300ms;
}

.footer a:hover {
  text-decoration-color: rgba(255, 255, 255, 1);
}

.footer .container .contact-logos {
  margin: 1rem 0;
}

.contact-logo {
  margin-right: 12px;
}

.copyright {
  border-top: 1px solid #ffffff;
  padding: 1rem 0 2rem;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.hover-pointer:hover {
  cursor: pointer;
}

.footer-width-col-4 {
  width: 33.33%;
}
.footer-width-col-2 {
  width: 16.66%;
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) {
  .footer > .container {
    margin: 0 calc((100vw - 1280px) * (136 - 64) / (1440 - 1280) + 114px);
  }
}
@media only screen and (max-width: 1024px) {
  .footer-width-col-4 {
    width: 100%;
  }
  .footer-width-col-2 {
    width: 180px;
  }
  .footer .company-description .description-header-logo {
    margin-top: 1rem;
  }
  .footer > .container {
    margin: 0 calc((100vw - 1024px) * (64 - 20) / (1280 - 1024) + 70px);
  }
}
@media only screen and (max-width: 768px) {
}
@media only screen and (max-width: 768px) {
  /* .footer-width-2 {
    width: 33%;
  } */
}
@media only screen and (max-width: 375px) {
  /* .footer-width-2 {
    width: 100%;
  } */
}
