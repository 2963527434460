@import "../index.css";

.talent-intro-container-1 {
  padding-top: 89px;
  padding-bottom: 114px;
}

.talent-intro-container-1 .talent-intro {
  max-width: 480px;
  padding-top: 40px;
}

.talent-intro-container-1 .talent-intro .title {
  margin-bottom: 0.5rem;

  line-height: 44px;
  letter-spacing: 0.03em;
}

.talent-intro-container-1 .talent-intro .sub-title {
  margin-bottom: 30px;

  line-height: 26px;
  letter-spacing: 0.06em;

  /* background-image: linear-gradient(90.23deg, #6473db -0.09%, #6e46c6 75.15%); */
  background-image: linear-gradient(269.55deg, #0094fb -3.91%, #9173d1 101.72%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  text-transform: uppercase;
}

.talent-intro-container-1 .talent-intro .paragraph {
  margin-bottom: 30px;

  line-height: 26px;
  letter-spacing: 0em;
}

.talent-intro-container-1 .intro-image {
  max-height: 324px;
  max-width: 460px;
}

.talent-intro-container-2 {
  /* padding-top: 10px; */
  padding-bottom: 49px;

  display: flex;
  align-items: center;

  color: white;
}

.talent-intro-container-2 .talent-intro {
  max-width: 500px;
  /* padding-top: 99px; */
}

.talent-intro-container-2 .talent-intro .title {
  margin-bottom: 34px;
}

.talent-intro-container-2 .intro-image {
  /* max-height: 483px; */
  max-width: 483px;
}

.talent-intro-container-2 .intro-image > img {
  height: 516px;
  width: 601px;
}

.talent-intro-feature-section {
  padding-top: 95px;
  padding-bottom: 171px;
}

.talent-intro-container-3,
.talent-intro-container-4,
.talent-intro-container-5 {
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.talent-intro-container-3 {
  margin-bottom: 55px;
}

.talent-intro-container-4 {
  margin-bottom: 71px;
}

.talent-intro-container-5 {
  margin-bottom: 120px;
}

.talent-intro-container-3 .talent-intro,
.talent-intro-container-5 .talent-intro {
  max-width: 480px;
}

.talent-intro-container-4 .talent-intro {
  max-width: 480px;
}

.talent-intro-container-3 .talent-intro .title,
.talent-intro-container-5 .talent-intro .title {
  margin-bottom: 24px;
}

.talent-intro-container-4 .talent-intro .title {
  margin-bottom: 35px;
}

.talent-intro-container-3 .intro-image {
  max-height: 336px;
  max-width: 443px;
}

.talent-intro-container-4 .intro-image {
  max-height: 284px;
  max-width: 384px;
}

.talent-intro-container-5 .intro-image {
  max-height: 332px;
  max-width: 258px;
}

@media only screen and (max-width: 920px) {
  .talent-intro-container-1 {
    padding-top: 64px;
    padding-bottom: 0;
    margin-bottom: calc(14.7vw + 64.5px);
  }

  .talent-intro-container-2 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: calc(26.7vw - 15.2px);
  }

  .talent-intro-feature-section {
    padding-top: 0;
  }

  .talent-intro-container-3 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 64px;
  }

  .talent-intro-container-4 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 64px;
  }

  .talent-intro-container-5 {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: calc(23vw - 35.6px);
  }

  .talent-intro-container-1 .talent-intro {
    margin-bottom: calc(14vw - 22.5px);
  }
  .talent-intro-container-2 .talent-intro {
    margin-bottom: calc(14vw + 30.5px);
  }
  .talent-intro-container-3 .talent-intro {
    margin-bottom: calc(8.6vw - 8.5px);
  }
  .talent-intro-container-4 .talent-intro {
    margin-bottom: calc(14.7vw - 31px);
  }
  .talent-intro-container-5 .talent-intro {
    margin-bottom: calc(10vw - 14px);
  }
}
