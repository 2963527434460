@import "./styles/fonts.css";
@import "./styles/backgrounds.css";

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
}

.container {
  margin: 0 20px;
}

.rectangle-right {
  display: none;
}


@media only screen and (min-width: 375px) {

}

@media only screen and (min-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .container {
    margin: 0 calc((100vw - 1024px) * (64 - 20) / (1280 - 1024) + 20px);
  }
}
@media only screen and (min-width: 1200px) {
  .rectangle-right {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .container {
    margin: 0 calc((100vw - 1280px) * (136 - 64) / (1440 - 1280) + 64px);
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    margin: 0 9.44%;
  }
}

body {
  color: #333333;
  /* background-color: #E5E5E5; */
}

.container h1 {
  color: #333333;

  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
}

.container p,
.container h3 {
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
}

.container h3 {
  font-weight: 700;

  background-image: linear-gradient(90.23deg, #6473db -0.09%, #6e46c6 75.15%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.container p {
  font-weight: 500;
}

.intro-paragraph h1 {
  line-height: 44px;
  letter-spacing: 0.03em;
  text-align: left;
}

.intro-paragraph h3 {
  margin: 0.5rem auto;

  line-height: 26px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.intro-paragraph p {
  line-height: 32px;
  letter-spacing: 0em;
}

.btn {
  width: 220px;
  height: 47px;
  border: none;
  border-radius: 6px;
  background-color: #0095fa;

  color: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  transition: ease-in-out 0.3s;
}

.btn:hover {
  cursor: pointer;
  background-color: #4eb0f4;
}

.btn:active {
  transform: scale3d(0.95, 0.95, 0.95);
}

.btn.sm {
  width: 136px;
  height: 36px;
  font-size: 14px;
}

.btn.md {
  width: 220px;
  height: 47px;
}

.btn.ghost {
  background-color: white;
  color: #0094FB;
  border: 1px solid #0094FB;
}

.btn.ghost:hover {
  background-color: #0094FB;
  color: white;
}

.hover-pointer:hover {
  cursor: pointer;
}

.row {
  display: flex;
}

.row-wrap {
  display: flex;
  flex-wrap: wrap;
}

.reverse-row {
  display: flex;
  flex-direction: row-reverse;
}

.align-items-center {
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.title {
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;

  margin-top: 0;
  margin-bottom: 0;
}

.sub-title {
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;

  margin-top: 0;
  margin-bottom: 0;
}

.paragraph {
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;

  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 1440px) {
  
}
@media only screen and (max-width: 1280px) {
  .intro-paragraph {
    width: 40%;
  }
}
@media only screen and (max-width: 1024px) {
  .registration-img-width {
    width: 50%;
  }

}
@media only screen and (max-width: 885px) {
  .intro-paragraph {
    width: 100%;
  }

  .registration-img-width {
    width: 61.5%;
  }

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 375px) {

}